<template>
  <div class="promo-tariff">
    <PromoItem
      class="promo-tariff__head"
      :color="promo.color"
      :title="promo.title"
      :caption="promo.desc"
      :images="promo.images"
    />
    <div class="promo-tariff__body">
      <Collapse color="grey">
        <template #head>Условия подключения</template>
        <template #body>
          <div class="promo-tariff__grid">
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/simcard.svg" width="32" height="32" />
              </template>
              <template #body>
                SIM-карта t2 с тарифным планом «Мой Онлайн»
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/alert-large.svg" width="32" height="32" />
              </template>
              <template #body
                >7 дней общения в подарок — не требуется оплата абонентской
                платы, все услуги доступны в полном объеме</template
              >
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/gift.svg" width="32" height="32" />
              </template>
              <template #body>
                <div>
                  <p>После окончания промо-периода</p>
                  <ul>
                    <li>
                      Через 7 дней абонентская плата списывается в полном объеме
                    </li>
                    <li>Все пакеты, доступные на тарифе, обновляются</li>
                    <li>
                      Следующее списание абонентской платы произойдет через
                      месяц после первого списания
                    </li>
                  </ul>
                </div>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Что входит в промо тариф?</template>
        <template #body>
          <div class="promo-tariff__grid">
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/infinity.svg" width="32" height="32" />
              </template>
              <template #body> Безлимитные звонки на t2 РФ </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/rouble.svg" width="32" height="32" />
              </template>
              <template #body>
                Неиспользованные остатки пакетов переносятся на следующий месяц
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/gift.svg" width="32" height="32" />
              </template>
              <template #body>
                Большие пакеты ГБ и минут для звонков на других операторов
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img
                  src="@/assets/32/share-internet.svg"
                  width="32"
                  height="32"
                />
              </template>
              <template #body>
                Можно бесплатно делиться ГБ из своего тарифа
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img src="@/assets/32/alert-large.svg" width="32" height="32" />
              </template>
              <template #body>
                SOS-пакет. <br />
                При нулевом или отрицательном балансе доступны приложения:
                <br />WhatsApp, Viber, Telegram, Я.Карты и Я.Навигатор.
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img
                  src="@/assets/32/social-network.svg"
                  width="32"
                  height="32"
                />
              </template>
              <template #body>
                Льготные условия в поездках по РФ. Минуты расходуются из пакета
                при звонках на номера региона пребывания, на остальные номер РФ
                – 2,00 ₽/мин. Интернет расходуется из пакета.
              </template>
            </ContentItem>
            <ContentItem color="white">
              <template #head>
                <img
                  src="@/assets/32/time-machine.svg"
                  width="32"
                  height="32"
                />
              </template>
              <template #body>
                Безлимитный трафик в соцсетях и мессенджерах
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import Collapse from "./Collapse.vue";
import ContentItem from "./ContentItem.vue";
import PromoItem from "./PromoItem.vue";

const promo = {
  color: "pink",
  title: `ДАРИМ 7 ДНЕЙ БЕСПЛАТНОГО ОБЩЕНИЯ`,
  desc: `При покупке любого смартфона, планшета или мобильного телефона`,
  images: [
    require("@/assets/bonuses/m.webp"),
    require("@/assets/bonuses/d.webp"),
  ],
};
</script>
<script>
export default {
  data() {
    return {
      collapsed1: false,
      collapsed2: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.promo-tariff {
  margin-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &__body {
    display: grid;
    gap: var(--widthGapV);
  }
  &__grid {
    display: grid;
    gap: var(--widthGapV);
    max-width: 1004px;
    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
