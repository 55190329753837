<template>
  <div class="navigation">
    <div class="navigation__item" v-for="(item, index) of nav" :key="index">
      <button @click="scroll(item.section)">
        <span>
          <img :src="require(`@/assets/${item.icon}`)" />
        </span>
        <span v-html="item.name"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      nav: (state) => state.nav.list,
    }),
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--widthGapV);
  @include md {
    grid-template-columns: repeat(12, 1fr);
  }
  &__item {
    grid-column: span 3;
    background-color: var(--colorWhite);
    border-radius: var(--radiusOuter);
    height: 125px;
    color: var(--colorBlack);
    &:nth-last-child(-n + 3) {
      grid-column: span 2;
    }
    // серый
    &:nth-child(6) {
      background-color: var(--colorGrey);
      button {
        & span:nth-child(1) {
          background-color: #d1d4dc;
        }
      }
    }
    // розовые
    &:nth-child(2),
    &:nth-child(7),
    &:nth-child(10) {
      background-color: var(--colorPink);
      color: var(--colorWhite);
      button {
        & span:nth-child(1) {
          background-color: #01010133;
        }
      }
    }
    // зеленые
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(8),
    &:nth-child(11) {
      background-color: var(--colorGreen);
      button {
        & span:nth-child(1) {
          background-color: #9ded00;
        }
      }
    }
    @include md {
      height: 200px;
      grid-column: span 3;
      &:nth-child(-n + 3) {
        grid-column: span 4;
      }
      &:nth-last-child(-n + 3) {
        grid-column: span 3;
      }
    }
    button {
      @include reset-button;
      width: 100%;
      height: 100%;
      display: grid;
      align-content: space-between;
      text-align: left;
      padding: var(--widthPadding);
      font-size: 12px;
      line-height: 16px;
      @include md {
        font-size: 15px;
        line-height: 22px;
      }
      & span:nth-child(1) {
        width: 48px;
        height: 48px;
        display: grid;
        place-content: center;
        border-radius: 16px;
        background-color: var(--colorGrey);
      }
      img {
        width: auto;
        height: auto;
      }
    }
  }
}
</style>
