import { createApp } from "vue";
import VueScrollTo from "vue-scrollto";
import App from "./App.vue";
import mixinDevice from "./mixins/device";
import mixinMethods from "./mixins/methods";
import router from "./router";
import store from "./store";
import { watchScreenResize } from "./utils";

watchScreenResize(store);

createApp(App)
  .use(store)
  .use(router)
  .mixin(mixinDevice)
  .mixin(mixinMethods)
  .use(VueScrollTo)
  .mount("#app");
