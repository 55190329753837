<template>
  <div class="self-reg">
    <PromoItem
      class="self-reg__head"
      :color="promo.color"
      :title="selfReg.title"
      :desc="promo.desc"
      :images="promo.images"
    />
    <p>
      <strong>Самостоятельная регистрация –</strong> это возможность просто,
      быстро, самостоятельно <br />активировать свою SIM-карту t2 в любое
      удобное время в любом месте без помощи продавца.
    </p>
    <div class="self-reg__grid">
      <Card class="self-reg__card" color="white">
        <div class="self-reg__number">1</div>
        <p>
          Предложи клиенту купить SIM-карту t2 и расскажи о преимуществах ее
          использования
        </p>
      </Card>
      <Card class="self-reg__card" color="black">
        <div class="self-reg__number">2</div>
        <p>Продай комплект подключения, как любой другой товар</p>
      </Card>
      <Card class="self-reg__card" color="green">
        <div class="self-reg__number">3</div>
        <p>
          Расскажи о плюсах самостоятельной регистрации (удобно, быстро и
          безопасно)
        </p>
      </Card>
      <Card class="self-reg__card" color="pink">
        <p>Регистрация SIM-карты через приложение t2: пошаговая инструкция</p>
        <QRLink
          src="/static/demo/qr.png"
          link="https://msk.t2.ru/help/article/sim-card-registration-moj-tele2?pageParams=askForRegion%3Dtrue&utm_source=qr-samoregvideo&utm_medium=qr-samoregvideo&utm_campaign=qr-samoregvideo&utm_id=trademarketing"
        />
      </Card>
    </div>
  </div>
</template>

<script setup>
import Card from "./Card.vue";
import PromoItem from "./PromoItem.vue";
import QRLink from "./QRLink.vue";

const promo = {
  color: "black",
  desc: `«t2» - это приложение для самостоятельного управления тарифом и
            услугами (Личный кабинет абонента). В приложении доступна
            самостоятельная регистрация SIM-карты клиентом.`,
  images: [require("@/assets/reg/m.webp"), require("@/assets/reg/d.webp")],
};
</script>

<script>
import { mediaUrl } from "@/utils";
import { mapState } from "vuex";

export default {
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  computed: {
    ...mapState({
      selfReg: (state) => state.selfReg.selfReg,
    }),
  },
};
</script>

<style lang="scss" scoped>
.self-reg {
  margin-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  &__grid {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__number {
    width: 48px;
    height: 48px;
    display: grid;
    place-content: center;
    border-radius: 16px;
    background-color: var(--colorGrey);
    font-weight: 800;
    font-family: var(--fontSecondary);
    font-size: 28px;
    color: var(--colorBlack);
  }
  &__card {
    display: grid;
    align-content: start;
    gap: var(--widthGapV);
    &.white {
      &:deep(.self-reg__number) {
        background-color: #e3e6e9;
      }
    }
  }
}
</style>
