<template>
  <div class="offer-item">
    <h2 class="offer-item-title">{{ mix.name }}</h2>
    <div class="offer-item-price">
      <h2 class="zero">{{ mix.discounted_price / 100 }}</h2>
      <span class="span">
        <span style="text-decoration: line-through">{{ mix.price / 100 }}</span>
        <span class="currency">₽</span>
      </span>
      <span class="grey">при первом подключении</span>
    </div>
    <div class="offer-item-collapse">
      <div
        @click="$emit('change-collapsed2')"
        class="offer-item-collapse-head"
        :class="{ open: collapsed2 }"
      >
        <div>
          Можно настроить/<br />
          Доступные к выбору
        </div>
        <CollapseSVG :collapsed="collapsed2" />
      </div>
      <div
        :class="{ collapsed: collapsed2 }"
        class="offer-item-collapse-content"
      >
        <div
          class="offer-item-collapse-grid"
          v-for="(discountsItem, index) in mix.discounts"
          :key="index"
        >
          <img :src="mediaUrl(discountsItem.icon)" />
          <p>
            <strong>{{ discountsItem.title }}</strong>
          </p>
          <div class="offer-item-collapse-flex" v-if="discountsItem.options">
            <div
              v-for="(option, index) of discountsItem.options"
              :key="index"
              :class="{
                black: option.availability === 'in_tarrif',
                disabled: option.availability === 'not_available',
              }"
              class="item"
            >
              {{ option.name }}
            </div>
          </div>
          <div
            class="offer-item-collapse-grid-desc"
            v-if="discountsItem.text"
            v-html="discountsItem.text"
          />
        </div>
        <div
          class="offer-item-collapse-grid"
          v-for="(item, index) in mix.another"
          :key="index"
        >
          <img :src="mediaUrl(item.icon)" alt="" />
          <p>
            <strong>{{ item.title }}</strong>
          </p>
          <div
            class="offer-item-collapse-grid-desc"
            v-if="item.text"
            v-html="item.text"
          />
        </div>
      </div>
    </div>
    <div class="offer-item-collapse">
      <div
        @click="$emit('change-collapsed1')"
        class="offer-item-collapse-head"
        :class="{ open: collapsed1 }"
      >
        <div>Всегда в подписках MiXX</div>
        <CollapseSVG :collapsed="collapsed1" />
      </div>
      <div
        :class="{ collapsed: collapsed1 }"
        class="offer-item-collapse-content"
      >
        <div
          class="offer-item-collapse-grid"
          v-for="(item, index) in mix.in_the_subscripton"
          :key="index"
        >
          <img :src="mediaUrl(item.icon)" alt="" />
          <p>
            <strong>{{ item.title }}</strong>
          </p>
          <div
            class="offer-item-collapse-grid-desc"
            v-if="item.text"
            v-html="item.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mediaUrl } from "@/utils";
import CollapseSVG from "./CollapseSVG.vue";

export default {
  name: "OfferItem",
  components: {
    CollapseSVG,
  },
  methods: {
    mediaUrl(url) {
      return mediaUrl(url);
    },
  },
  props: {
    mix: {
      type: Object,
      required: true,
    },
    collapsed1: {
      type: Boolean,
      default: false,
    },
    collapsed2: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-item {
  background: #fff;
  border-radius: var(--radiusOuter);
  padding: var(--widthPadding);
  width: 100%;
  height: 100%;

  &.black {
    background: var(--colorBlack);
    color: #fff;

    .offer-item-collapse-head:after {
      filter: invert(1);
    }
  }
  &.primary {
    background: var(--colorPrimaryBg);
    color: var(--colorPrimaryText);

    .offer-item-collapse-head:after {
      filter: invert(1);
    }
    .offer-item-price {
      border-bottom-color: var(--colorWhite);
    }
    .offer-item-price span:after {
      border-bottom-color: var(--colorWhite);
    }
    .offer-item-collapse {
      background: #0101011a;
    }
  }

  &-title {
    font-family: var(--fontSecondary);
    font-weight: 800;
    font-size: var(--sizeHeading3);
    line-height: var(--lineHeightHeading3);
    margin: 0;
    margin-bottom: var(--widthGapV);
  }

  &-price {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--colorBlack);
    padding-bottom: 8px;
    .zero {
      font-family: var(--fontSecondary);
      font-weight: 800;
      font-size: 24px;
      line-height: var(--lineHeightHeading3);
      margin-right: 8px;
    }

    .span {
      font-weight: 800;
      font-size: 16px;
      line-height: var(--lineHeightHeading3);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      span {
        text-decoration: none !important;
      }
      &::after {
        content: "";
        position: absolute;
        width: 36px;
        border-bottom: 2px solid var(--colorPink);
        left: 0;
        top: 45%;
        transform: rotate(-20deg);
      }

      .currency {
        font-size: 13px;
        line-height: 20px;
        margin-left: 4px;
      }
    }

    .grey {
    }
  }

  &-collapse {
    margin-top: 8px;
    background-color: var(--colorGrey);
    border-radius: var(--radiusOuter);
    padding: var(--widthPadding);
    &-head {
      font-family: var(--fontSecondary);
      font-weight: 800;
      font-size: var(--sizeButton);
      line-height: var(--lineHeightButton);
      position: relative;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 8px;
      &:deep(svg) {
        .bg {
          fill: var(--colorBlack);
        }
        .plus,
        .minus {
          fill: var(--colorWhite);
          stroke: var(--colorWhite);
        }
      }
    }

    &-content {
      height: 0;
      overflow: hidden;
      display: grid;
      grid-gap: 7px;

      &.collapsed {
        height: auto;
        padding-top: 8px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: 32px 1fr;
      grid-gap: 8px;
      margin-right: -2px;
      align-items: center;
      img {
        width: 32px;
      }

      p {
        margin: 0;
        display: flex;
        align-items: center;
        min-height: 45px;

        strong {
          line-height: 22px;
          font-weight: 400;
          font-family: var(--fontPrimary);
        }
      }

      &-desc {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    .hidden {
      visibility: hidden;
    }

    &-flex {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      grid-column-start: 1;
      grid-column-end: 3;
      width: 100%;

      .item {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 5px 10px;
        border: 1px solid #54576b;
        border-radius: 8px;
        margin: 3px 2.5px;

        &.black {
          color: #fff;
          background: #3a3c4b;
        }

        &.disabled {
          opacity: 0.1;
        }
      }
    }
  }
}
</style>
